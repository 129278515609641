import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/BrigadeComposition.css';
import Table from '../Table/Table';
import ResetMesh from './ResetMesh';
import BrigadeCheckDevices from './BrigadeCheckDevices';
import BrigadeAddDevices from './BrigadeAddDevices';
import BrigadeExceptionDevise from './BrigadeExceptionDevise';
import CustomButton from '../CustomElements/CustomButton';
import Modal from '../Modal/Modal';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import {
  emitBrigadeComposition,
  emitBrigadeCompositionRemoveDevice,
  emitResetMesh,
} from '../../redux/actions/brigades.action';
import {
  listenBrigadeCompositionInfo,
  listenBrigadeCompositionRemoveDevice,
  listenResetMesh,
} from '../../redux/actions/brigades.action';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { useRole } from '../../customHook/useRole';

/**
 * Компонент для отображения состава бригады и управления устройствами.
 */

function BrigadeComposition({ currentBrigade, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //проверяем роли пользователя
  const isMaster = useRole('master');
  const isCustomer = useRole('customer');
  const isDispatcher = useRole('dispatcher');

  // Получение данных о бригадах из Redux store.
  const { brigadeComposition } = useSelector((state) => state.brigadesState);

  // Использование кастомного хука для управления модальными окнами
  const { isOpen, activeModal, closeModal, handleButtonClick, device } = useModalWithStatus();

  // Обработчики для подтверждения действий в различных модальных окнах
  const handlerConfirmExceptionDevise = (id) => {
    //отправляем событие чтобы исключить ИУ из  бригады
    const payload = { brigadeId: currentBrigade.id, deviceId: id };
    dispatch(emitBrigadeCompositionRemoveDevice(payload));
    closeModal();
  };
  const handlerConfirmCheckDevices = () => {
    dispatch(emitBrigadeComposition({ brigadeId: currentBrigade.id }));
  };

  // сброс mesh
  const handlerConfirmResetMesh = (id) => {
    const payload = { deviceId: id };
    dispatch(emitResetMesh(payload));
  };

  // Определение содержимого для модальных окон
  const contentData = useMemo(
    () => ({
      [t?.checkDevices]: <BrigadeCheckDevices t={t} />,
      [t?.addDevices]: <BrigadeAddDevices closeModal={closeModal} currentBrigade={currentBrigade} t={t} />,
      [t?.exclude]: (
        <BrigadeExceptionDevise
          exceptionDevice={device}
          closeModal={closeModal}
          handlerConfirm={handlerConfirmExceptionDevise}
          t={t}
        />
      ),
      [t?.resetMeshAction]: (
        <ResetMesh
          closeModal={closeModal}
          confirmReset={false}
          handlerConfirm={handlerConfirmResetMesh}
          device={device}
          t={t}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, currentBrigade, device]
  );

  // Колонки таблицы
  const columns = useMemo(
    () =>
      [
        {
          Header: t?.type,
          accessor: 'type',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.deviceId,
          accessor: 'id',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.status,
          accessor: 'online',
          Cell: ({ value }) => defaultCellRender(value ? t?.active : t?.inactive),
        },
        {
          Header: t?.location,
          accessor: 'inZone',
          Cell: ({ value, row }) =>
            defaultCellRender(row.original.type === 'ИУ' ? (Number(value) ? t?.inZone : t?.outOfZone) : '-'),
        },
        {
          Header: t?.condition,
          accessor: 'viable',
          Cell: ({ value }) => defaultCellRender(value ? t?.working : t?.notWorking),
        },
        {
          Header: t?.battery,
          accessor: 'battery',
          Cell: ({ value }) => defaultCellRender(value),
        },
        (!isCustomer && !isMaster && !isDispatcher) && {
          Header: t?.resetMesh,
          accessor: 'resetMesh',
          Cell: ({ row }) => (
            <div className="">
              {row.original.type === 'КУОБЗ' || row.original.type === 'KUOBZ' ? (
                <CustomButtonDelete
                  margin="0  var(--sadr-font-size-5) "
                  disabled={isCustomer}
                  onClick={() => {
                    handleButtonClick(t.resetMeshAction, null, row?.original);
                  }}
                />
              ) : (
                <span>-</span> // Отображать прочерк, если тип не "КУОБЗ"
              )}
            </div>
          ),
        },
        (!isCustomer && !isMaster && !isDispatcher) && {
          Header: t?.action,
          Cell: ({ row }) => {
            return (
              <div className="">
                {row.original.type !== 'ИУР' && row.original.type !== 'IUR' ? (
                  <CustomButtonDelete
                    margin="0  var(--sadr-font-size-5)"
                    disabled={isCustomer}
                    onClick={() => {
                      handleButtonClick(t?.exclude, null, row?.original);
                    }}
                  />
                ) : (
                  <span>-</span>
                )}
              </div>
            );
          },
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brigadeComposition, t]
  );

  useEffect(() => {
    //отправляем событие чтобы получить состав бригады
    dispatch(emitBrigadeComposition({ brigadeId: currentBrigade.id }));
    //слушаем событие 'brigade_get_reply'
    dispatch(listenBrigadeCompositionInfo());
    //слушаем событие 'brigade_remove_reply'
    dispatch(listenBrigadeCompositionRemoveDevice());
    //слушаем событие 'reset_mesh_reply'
    dispatch(listenResetMesh());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="brigade-composition">
      {brigadeComposition && (
        <div className="brigade-composition_table-container">
          <Table data={brigadeComposition} columns={columns} />
        </div>
      )}

      {/* Блок с кнопками */}
      {(!isCustomer && !isMaster && !isDispatcher) && (
        <div className="brigade-composition_button-container flex-wrap flex-md-nowrap">
          {Object.keys(contentData)
            .slice(0, -2)
            .map((buttonName) => (
              <CustomButton
                className="brigade-composition__button"
                key={buttonName}
                text={buttonName}
                colorText="#FFFFF"
                onClick={
                  buttonName === t?.checkDevices ? handlerConfirmCheckDevices : () => handleButtonClick(buttonName)
                }
                margin={'var(--sadr-font-size-15) '}
                disabled={false}
              />
            ))}
        </div>
      )}
      {/* Модальное окно */}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.brigades_content'}>
          {contentData[activeModal]}
        </Modal>
      )}
    </div>
  );
}

export default BrigadeComposition;
